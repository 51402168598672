import React, { createContext, useContext, useEffect, useState } from "react";
import Items from "./components/Items";
import Pagination from "./components/Pagination";
import cat from "./category_model";
import prod from "./products";
import cattop from "./assets/cattop.svg";
import "./Categ.scss";
import arrow from "./assets/arrow.svg";
import { CurrentCat } from "./App";

const Categories = () => {
  const [
    currCat,
    setCurrCat,
    pgindex,
    setPgindex,
    currItem,
    setCurrItem,
    currentPage,
    setCurrentPage,
  ] = useContext(CurrentCat);
  // const [currCat, setCurrCat] = useState(null);
  //   const [items, setItems] = useState([]);
  //   const [cat, setCat] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [currItem, setCurrItem] = useState("All products");
  const [itemPerPage] = useState(10);
  //   useEffect(() => {
  //     const fetchCategory = async () => {
  //       setLoading(true);
  //       await fetch("http://127.0.0.1:8000/api/categories")
  //         .then((res) => {
  //           return res.json();
  //         })
  //         .then((res) => setCat(res))
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //       setLoading(false);
  //     };
  //     const fetchItems = async () => {
  //       setLoading(true);
  //       await fetch("http://127.0.0.1:8000/api/products")
  //         .then((res) => {
  //           return res.json();
  //         })
  //         .then((res) => setItems(res))
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //       setLoading(false);
  //     };
  //     return () => {
  //       fetchItems();
  //       fetchCategory();
  //     };
  //   }, []);

  const handleDrop = () => {
    const ele1 = document.querySelector(".inactiveDrop");
    ele1.classList.toggle("activeDrop");
    const ar = document.querySelector(".notInverted");
    ar.classList.toggle("inverted");
  };

  var lastPageIndex = currentPage * itemPerPage;
  var firstPageIndex = lastPageIndex - itemPerPage;
  if (currCat === null) {
    var currentItems = prod.slice(firstPageIndex, lastPageIndex);
  } else {
    var arr = [];
    for (let i = 0; i < prod.length; i++) {
      if (prod[i].category === currCat) {
        arr.push(prod[i]);
      }
    }
    var currentItems = arr.slice(firstPageIndex, lastPageIndex);
  }
  var paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex justify-center w-screen flex-col items-center gap-5 my-7">
      <div className="w-screen py-3">
        <img
          src={cattop}
          alt="black post"
          className="lg:xl:w-[80.7vw] mx-auto w-[90vw]"
        />
      </div>
      <div className="lg:xl:flex hidden flex-wrap gap-[32px] text-[20px] text-[#F24949] font-normal font-urban my-5">
        <button
          onClick={() => {
            setCurrCat(null);
            setPgindex(-1);
            setCurrentPage(1);
          }}
          className={`${pgindex === -1 ? "underline font-semibold" : ""}`}
        >
          All Products
        </button>
        {cat.map((item, index) => {
          return (
            <button
              onClick={() => {
                setCurrCat(item.id);
                setPgindex(index);
                setCurrentPage(1);
              }}
              key={index}
              className={`${
                pgindex === index ? "underline font-semibold" : ""
              }`}
            >
              {item.name}
            </button>
          );
        })}
      </div>
      <div className=" mobiledrop w-[85vw] lg:xl:hidden">
        <div className="drop text-[20px] text-[#F24949] flex w-auto rounded-lg relative">
          <div
            className=" border-[#F24949] border-2 flex w-[181px] rounded-lg"
            onClick={handleDrop}
          >
            <section className="px-[20px]  bg-white py-2 pr-2 rounded-lg w-[80%] underline font-semibold">
              {currItem}
            </section>
            <div className="arow icon flex gap-1 justify-center items-center  bg-white pr-[10px] w-[20%] rounded-lg ">
              <img src={arrow} alt="arrow" className="notInverted" />
            </div>
          </div>
          <ul className="inactiveDrop hidden flex-col gap-4 bg-white p-3 absolute left-0 top-[40px] z-50 px-[30px] shadow-xl border-[#F24949] border-b-2 border-r-2 border-l-2 rounded-b-lg w-[181px]">
            <li
              onClick={() => {
                setCurrItem("All products");
                setCurrCat(null);
                setPgindex(-1);
                setCurrentPage(1);
                handleDrop();
              }}
              className={`${
                pgindex === -1 ? "underline font-semibold" : ""
              } cursor-pointer`}
            >
              All products
            </li>
            {cat.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setCurrItem(item.name);
                    setCurrCat(item.id);
                    setPgindex(index);
                    handleDrop();
                    setCurrentPage(1);
                  }}
                  className={`${
                    pgindex === index ? "underline font-semibold" : ""
                  } cursor-pointer`}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
        {/* <select
          name="filter"
          id="filters"
          className="bg-white text-[20px] text-[#F24949] py-[5px] px-[10px] border-[1px] border-[#F24949] rounded-sm  "
        >
          <option
            onClick={() => {
              setCurrCat(null);
              setPgindex(-1);
            }}
            className={`${pgindex === -1 ? "underline font-semibold" : ""}`}
            value=""
          >
            All Products
          </option>
          {cat.map((item, index) => {
            console.log(currCat);
            return (
              <option
                onClick={() => {
                  setCurrCat(item.id);
                  setPgindex(index);
                }}
                key={index}
                value={item.id}
                className={`${
                  pgindex === index ? "underline font-semibold" : ""
                }`}
              >
                {item.name}
              </option>
            );
          })}
        </select> */}
      </div>

      <Items currentItems={currentItems} />
      <Pagination
        itemPerPage={itemPerPage}
        totalItems={arr ? arr.length :prod.length}
        paginate={paginate}
      />
    </div>
  );
};

export default Categories;
