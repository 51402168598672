import newlyIn from './assets/newly.png'
const newly=[
    {
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: newlyIn
    },
    {
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: newlyIn
    },
    {
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: newlyIn
    },
    {
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: newlyIn
    },
    {
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: newlyIn
    },
    {
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: newlyIn
    },
]

export default newly