import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Categories from './Categories'
import logo from "./assets/logo.svg";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Contact from "./Contact";
import { createContext, useEffect, useState } from "react";
import sandwich from "./assets/nav.svg";
import React from 'react';



const Header = () => {
  let location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // const [active, setActive] = useState(false);
  const handleClick = () => {
    const ele = document.querySelector(".inactive");
    ele.classList.toggle("active");
    // setActive(true);
  };
  return (
    <div className="navbar head flex justify-center flex-col w-screen items-center ">
      <div className="fixed top-0 z-20 py-8 right-0 lg:xl:hidden flex justify-end pr-7">
        <img src={sandwich} onClick={handleClick} className="cursor-pointer" alt="sandwich" />
      </div>
      <div className="inactive hidden py-[90px] fixed top-0 w-screen z-10  justify-center flex-col items-center bg-white rounded-b-lg shadow-2xl">  
        <Link
          to="/"
          className={`cool-link my-[13px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out text-[20px] ${
            location === "/" ? "text-[#F24949] font-bold after:hover:w-0" : ""
          }`}
          onClick={handleClick}
        >
          Home
        </Link>
        <Link
          to="/about"
          className={`cool-link my-[13px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out text-[20px] ${
            location === "/about"
              ? "text-[#F24949] font-bold after:hover:w-0"
              : ""
          }`}
          onClick={handleClick}
        >
          About
        </Link>
        <Link
          to="/categories"
          className={`cool-link my-[13px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out text-[20px] ${
            location === "/categories"
              ? "text-[#F24949] font-bold after:hover:w-0"
              : ""
          }`}
          onClick={handleClick}
        >
          Categories
        </Link>
        <Link
          to="/contact"
          className={`cool-link my-[13px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out text-[20px] ${
            location === "/contact"
              ? "text-[#F24949] font-bold after:hover:w-0"
              : ""
          }`}
          onClick={handleClick}
        >
          Contact
        </Link>
      </div>
      <div className="logo w-full flex flex-col items-center justify-center mt-[32px] mb-[29px]">
        <img src={logo} alt="logo" className="xl:lg:w-[100px] w-[62px]" />
        <h1 className="lg:xl:mt-[13px] mt-[8px] xl:lg:text-[24px] text-[15px] font-urban-500 font-bold">
          Shivani Jewellers
        </h1>
        <h1 className="lg:xl:mt-[4px] mt-[2px] xl:lg:text-[18px] text-[10px] font-urban-500 font-bold text-[#F24949]">Name of Trust</h1>
      </div>
      <div className="navbar1 lg:xl:flex bg-[white] rounded-xl px-[280px] hidden">
        <nav className="flex font-urban font-normal text-[20px] ">
          <Link
            to="/"
            className={`cool-link my-[23px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out ${
              location === "/" ? "text-[#F24949] font-bold after:hover:w-0" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`cool-link my-[23px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out ${
              location === "/about"
                ? "text-[#F24949] font-bold after:hover:w-0"
                : ""
            }`}
          >
            About
          </Link>
          <Link
            to="/categories"
            className={`cool-link my-[23px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out ${
              location === "/categories"
                ? "text-[#F24949] font-bold after:hover:w-0"
                : ""
            }`}
          >
            Categories
          </Link>
          <Link
            to="/contact"
            className={`cool-link my-[23px] w-[170px] flex justify-center items-center font-urban hover:font-bold hover:text-[#F24949] transition-all duration-[.1s] ease-in-out ${
              location === "/contact"
                ? "text-[#F24949] font-bold after:hover:w-0"
                : ""
            }`}
          >
            Contact
          </Link>
        </nav>
      </div>
    </div>
  );
};
export const CurrentCat = createContext()
function App() {
  const [currCat,setCurrCat] = useState(null)
  const [pgindex, setPgindex] = useState(-1);
  const [currItem, setCurrItem] = useState("All products");
  const [currentPage, setCurrentPage] = useState(1);
  const [total,setTotal] = useState([])
  const [open, setOpen] = useState(false)
  return ( 
    <CurrentCat.Provider value={[currCat,setCurrCat,pgindex,setPgindex,currItem,setCurrItem,currentPage, setCurrentPage,total,setTotal,open, setOpen]} >
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home  />}  />
        <Route path="/about" element={<About />}  />
        <Route path="/categories" element={<Categories />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </CurrentCat.Provider>
  );
}

export default App;
