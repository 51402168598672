import React, { createContext, useContext, useEffect, useState } from "react";
import payal from "./assets/homeImg.svg";
import Brand from "./components/Brand";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./home.scss";
import newly from "./newly";
import cat from "./category_model";
import prod from "./products";
import star from "./assets/star.svg";
import whats from "./assets/whatsapp.png";
import AOS from "aos";
import black from "./assets/black.svg";
import "aos/dist/aos.css";
import Categories from "./Categories";
import { CurrentCat } from "./App";
import Modals from "./components/Models";

const Home = () => {
  const [
    currCat,
    setCurrCat,
    pgindex,
    setPgindex,
    currItem,
    setCurrItem,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
    open,
    setOpen,
  ] = useContext(CurrentCat);
  const [select, setSelect] = useState(0);
  const [selimag, setSelimag] = useState(null);
  // const [prod, setProd] = useState([]);
  // const [newly,setNewly] = useState([])
  // const [cat,setCat]= useState([])
  // const [err,setErr] = useState(false)
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  // useEffect(() => {
  //   const prodfun = async() => {
  //     try {
  //       await fetch("http://127.0.0.1:8000/api/products")
  //       .then((res) => res.json())
  //       .then((res) => setProd(res));

  //     } catch (error) {
  //       setErr(true)
  //     }

  //   };
  //   const newly = async () => {
  //     try {
  //       await fetch("http://127.0.0.1:8000/api/newlyIn")
  //       .then((res) => res.json())
  //       .then((res) => setNewly(res));
  //     } catch (error){
  //       setErr(true)
  //     }
  //   }
  //   const catfun = async() => {
  //     try {
  //       await fetch("http://127.0.0.1:8000/api/categories")
  //       .then((res) => res.json())
  //       .then((res) => setCat(res));

  //     } catch (error) {
  //       setErr(true)
  //     }
  //   }
  //   return () => {
  //     prodfun();
  //     catfun();
  //     newly();
  //   };
  // }, []);

  // var items2 = [
  //   { url: payal2 },
  //   { url: payal2 },
  //   { url: payal2 },
  //   { url: payal2 },
  //   { url: payal2 },
  //   { url: payal2 },
  //   { url: payal2 },
  // ];

  const settings2 = {
    dots: false,
    autoplay: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
  };
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Home w-screen flex flex-col overflow-x-hidden">
      <div
        className="w-full flex justify-center mt-[1.5vw] px-[7vw] "
        data-aos="fade-up"
      >
        <img src={payal} alt="payal" />
      </div>
      <div className="featured flex flex-col justify-center items-center lg:xl:mt-[69px] mt-[30px]">
        <div>
          <div className="w-full flex justify-center items-end">
            <section className="border-[#F24949] border-[2px] rounded-3xl px-5 flex justify-center items-center bg-white">
              <h1 className=" text-[#F24949] lg:xl:text-[32px] text-[15px] flex justify-center xl:lg:pb-1 pb-[2px] font-semibold">
                Featured Payals
              </h1>
            </section>
          </div>
        </div>
        <div className="slider lg:xl:w-[80vw] w-[90vw] mt-[24px] overflow-x-hidden">
          <Slider {...settings}>
            {prod.map((obj, index) => {
              console.log(select,obj.id)
              return (
                <div
                className="flex flex-col items-center justify-center bg-gradient-to-br lg:xl:w-[330px] w-[160px] "
                key={index}
                onClick={() => {
                  setOpen(true);
                  setSelect(obj.id);
                  setSelimag(obj.image);
                }}
              >
                <div className="overflow-hidden cursor-pointer rounded-xl relative group shadow-md">
                  <div className="rounded-xl z-20 opacity-0 lg:xl:group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
                    <div className="w-full">
                        <div className="w-full flex justify-between p-4 space-y-3 text-xl group-hover:opacity-100 lg:xl:group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out">
                          <div>
                            <div className="font-bold my-3">{obj.name}</div>
                            <div className="opacity-60 text-sm ">
                              Tap to View
                            </div>
                          </div>
                          <img src={whats} alt="" className="w-[50px]" />
                        </div>
                    </div>
                  </div>
                  {/* <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`} > */}
                    <div
                      className={`${obj.id === select ? "flex" : "hidden"}`}
                    >
                      <Modals imag={selimag} />
                    </div>
                    <img
                      alt="payal jwellery"
                      className="object-fill aspect-square lg:xl:group-hover:scale-110 transition duration-300 ease-in-out lg:xl:w-[304px] xl:lg:h-[344px] w-[157px] h-[195px]"
                      src={obj.image}
                    />
                  {/* </a> */}
                </div>
                <div>
                  <small
                    className="text-sm text-[#F24949] pl-1 cursor-pointer"
                    onClick={() => {
                      setOpen(true);
                      setSelect(obj.id);
                      setSelimag(obj.image);
                    }}
                  >
                    Tap to View
                  </small>
                </div>
              </div>
              );
            })}
          </Slider>
        </div>
        <div className="newly lg:xl:mt-[92px] mt-[55px]">
          <div className="lower lg:xl:w-[80vw] w-[90vw] flex justify-center lg:xl:flex-row flex-col">
            <div className="right lg:xl:w-1/2 w-full">
              <Slider {...settings2}>
                {newly.map((item, index) => {
                  return (
                    <div
                      className="flex items-center justify-center bg-gradient-to-br w-[330px]"
                      key={index}
                    >
                      <div className="overflow-hidden cursor-pointer xl:lg:rounded-l-xl rounded-t-xl relative group">
                        <div>
                          <small className="bg-[#F24949] text-white lg:xltext-[24px] text-[15px] absolute z-10 top-[15px] left-[15px] px-[15px] py-[5px] rounded-3xl">
                            Newly In
                          </small>
                        </div>
                        <div className="rounded-xl z-20 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
                          <div>
                            <div className="p-4 space-y-3 text-xl group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out">
                              <div className="font-bold">{item.name}</div>
                              <div className="opacity-60 text-sm ">
                                {item.desc}
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          alt="payal jwellery"
                          className="object-cover lg:xl:aspect-square group-hover:scale-110 scale-105 transition duration-300 ease-in-out lg:xl:w-[40.36vw] w-full"
                          src={item.image}
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="left lg:xl:w-1/2 w-full bg-white flex flex-col justify-center items-center lg:xl:pl-8 lg:xl:py-[0px] py-[70px] lg:xl:rounded-none rounded-b-md lg:xl:mt-0 -mt-1">
              <div className="img w-[80%]" data-aos="fade-up">
                <img
                  src={star}
                  alt="star"
                  className="w-[33px] lg:xl:w-[60px]"
                />
              </div>
              <div
                className="heading w-[80%] lg:xl:mt-[12px] mt-2"
                data-aos="fade-up"
              >
                <h1 className="text-[#F24949] font-urban font-bold lg:xl:text-[32px] text-[18px] lg:xl:leading-[38px] leading-[21px]">
                  Shimmer & Shine
                  <br /> with Exquisite Silver Anklets
                </h1>
              </div>
              <div
                className="para w-[80%] mt-[10px] pr-[68px]"
                data-aos="fade-up"
              >
                <p className="font-thin font-urban lg:xl:text-[20px] text-[11px] lg:xl:leading-[24px] leading-[14px]">
                  Discover the perfect blend of elegance and grace with our
                  stunning collection of silver anklets.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="categories flex flex-col justify-center items-center lg:xl:w-[80vw] w-[95vw]">
          <div className="upper mt-[70px]">
            <div className="w-full flex justify-center items-end">
              <section className="border-[#F24949] border-[2px] rounded-3xl px-5 flex justify-center items-center bg-white">
                <h1 className=" text-[#F24949] lg:xl:text-[32px] text-[15px] flex justify-center xl:lg:pb-1 pb-[2px] font-semibold">
                  Search By Categories
                </h1>
              </section>
            </div>
          </div>
          <div className="lower flex gap-2 over mt-[26px] flex-wrap justify-center">
            {cat.map((item, index) => {
              return (
                <div
                  key={index}
                  className="lg:xl:hover:scale-110 transition-all duration-[.3s]"
                >
                  <div
                    className="w-[167px] border-[#F24949] border-2 rounded-lg lg:xl:hover:shadow-lg "
                    key={index}
                  >
                    <Link
                      to="/categories"
                      onClick={() => {
                        setCurrCat(item.id);
                        setPgindex(index);
                        setCurrItem(item.name);
                        setCurrentPage(1);
                      }}
                    >
                      <img
                        src={item.thumbnail}
                        alt={item.name}
                        className="w-[167px] h-[169px] rounded-lg"
                      />
                    </Link>
                  </div>
                  <small className="text-[#F24949] text-[16px] mt-[18px]">
                    {item.name}
                  </small>
                </div>
              );
            })}
          </div>
        </div>
        <div className="lg:xl:mt-[45px] w-screen mt-[30px]">
          <img
            src={black}
            alt="black"
            className="lg:xl:w-[80.7vw] mx-auto w-[90vw]"
          />
        </div>
      </div>
      <Brand />
    </div>
  );
};

export default Home;
