import React from "react";
import { Link } from "react-router-dom";
import fb from '../assets/facebook.svg'
import insta from '../assets/instagram.svg'
import whatsapp from '../assets/Whatsapp.svg'
const Footer = () => {

  return (
    <div className="w-screen flex flex-col items-center justify-center mt-3 bg-white py-7 overflow-x-hidden">
      <div className="w-[80%] border-y-[2px] border-[#02071B] lg:xl:flex h-[310px] text-[#02071B] hidden">
        <div className="w-1/4 h-full px-3 pr-[62px]">
          <h1 className="text-[20px] font-bold font-urban mt-[32px]">
            About Us
          </h1>
          <p className="text-[14px] font-thin font-urban mt-[15px]">
            We understand that jewellery is more than just an accessory; it is a
            form of self-expression. That's why we offer customization options,
            allowing you to create a truly unique anklet that embodies your
            personal style.
          </p>
        </div>
        <div className="w-1/4 flex flex-col h-full px-3 pr-[40px]">
          <div className="h-[50%] w-full">
            <h1 className="text-[20px] font-bold font-urban mt-[32px]">
              Contact Us
            </h1>
            <p className="text-[14px] font-thin font-urban mt-[15px]">
              B-9/10, Dauji Complex, Kinari Bazar, Agra - 282003
            </p>
          </div>
          <div className="h-[50%] w-full">
            <h1 className="text-[20px] font-bold font-urban">
              <a href="tel:0562-6713836">0562-6713836</a>
            </h1>
            <p className="text-[14px] font-thin font-urban mt-[15px]">
              {" "}
              <a href="mailto: shivanijewellersagra@gmail.com">
                shivanijewellersagra@gmail.com
              </a>{" "}
            </p>
          </div>
        </div>
        <div className="w-1/4 px-3 pr-[40px]">
          <h1 className="text-[20px] font-bold font-urban mt-[32px]">
            Quick Links
          </h1>
          <div className="flex flex-col font-urban gap-3 mt-[15px]">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/categories">Categories</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="w-1/4 flex gap-3 justify-center pt-7">
          <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`}><img src={insta} alt="" /></a>
          <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`}><img src={fb} alt="" /></a>
          <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`}><img src={whatsapp} alt="" /></a>
        </div>
      </div>
      <div className="lg:xl:hidden flex w-screen justify-center items-center flex-col">
        <div className="w-[90vw] h-full px-3 justify-center items-center flex-col">
          <h1 className="text-[20px] font-bold font-urban mt-[32px] text-center">
            About Us
          </h1>
          <p className="text-[14px] font-thin font-urban mt-[15px] text-center">
            We understand that jewellery is more than just an accessory; it is a
            form of self-expression. That's why we offer customization options,
            allowing you to create a truly unique anklet that embodies your
            personal style.
          </p>
        </div>
        <div className="w-[90vw]">
          <h1 className="text-[20px] font-bold font-urban mt-[32px] text-center">
            Contact Us
          </h1>
          <p className="text-[14px] font-thin font-urban mt-[15px] text-center">
            B-9/10, Dauji Complex, Kinari Bazar, Agra - 282003
          </p>
        </div>
        <div className="w-[90vw] mt-[32px]">
          <h1 className="text-[20px] font-bold font-urban text-center">
            <a href="tel:0562-6713836">0562-6713836</a>
          </h1>
          <p className="text-[14px] font-thin font-urban mt-[15px] text-center">
            {" "}
            <a href="mailto: shivanijewellersagra@gmail.com">
              shivanijewellersagra@gmail.com
            </a>{" "}
          </p>
        </div>
        <div className="w-[90vw] px-3 flex flex-col items-center justify-center">
          <h1 className="text-[20px] font-bold font-urban mt-[32px] text-center">
            Quick Links
          </h1>
          <div className="flex flex-col font-urban gap-3 mt-[15px] items-center">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/categories">Categories</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="w-screen flex gap-3 justify-center pt-7">
          <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`}><img src={insta} alt="" className="lg:xl:w-[53px] w-[40px]" /></a>
          <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`}><img src={fb} alt="" className="lg:xl:w-[53px] w-[40px]"/></a>
          <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`}><img src={whatsapp} alt="" className="lg:xl:w-[53px] w-[40px]"/></a>
        </div>
      </div>
      <div className="text-[#02071B] lg:xl:text-[20px] text-[15px] font-bold w-[80%] mt-[39px] mb-[5px] flex justify-center">
        <h1 className="text-center">@2023 Shivani Jewellers | All Rights Reserved</h1>
      </div>
    </div>
  );
};

export default Footer;
