import s1 from './assets/golapayal/s1.png'
import s2 from './assets/golapayal/s2.png'
import s3 from './assets/golapayal/s3.png'
import s4 from './assets/golapayal/s4.png'
import s5 from './assets/golapayal/s5.png'
import j1 from './assets/jhallar/j1.png'
import j2 from './assets/jhallar/j2.png'
import j3 from './assets/jhallar/j3.png'
import p1 from './assets/parda/p1.png'
import p2 from './assets/parda/p2.png'
import p3 from './assets/parda/p3.png'
import p4 from './assets/parda/p4.png'
import f1 from './assets/fitting/f1.png'
import f2 from './assets/fitting/f2.png'
import f3 from './assets/fitting/f3.png'
import sa1 from './assets/sada/sa1.png'
import sa2 from './assets/sada/sa2.png'
import sa3 from './assets/sada/sa3.png'
import t1 from './assets/tukda/t1.png'
import t2 from './assets/tukda/t2.png'
import t3 from './assets/tukda/t3.png'

const prod = [
    {
        id:1,   
        name:"payal",
        category:4,
        desc: "Click for Query",
        image: s1
    },
    {
        id:2,
        name:"payal",
        category:4,
        desc: "Click for Query",
        image: s2
    },
    {
        id:3,
        name:"payal",
        category:4,
        desc: "Click for Query",
        image: s3
    },
    {
        id:4,
        name:"payal",
        category:4,
        desc: "Click for Query",
        image: s4
    },
    {
        id:5,
        name:"payal",
        category:4,
        desc: "Click for Query",
        image: s5
    },
    {
        id:6,
        name:"payal",
        category:7,
        desc: "Click for Query",
        image: j1
    },
    {
        id:7,
        name:"payal",
        category:7,
        desc: "Click for Query",
        image: j2
    },
    {
        id:8,
        name:"payal",
        category:7,
        desc: "Click for Query",
        image: j3
    },
    {
        id:9,
        name:"payal",
        category:2,
        desc: "Click for Query",
        image: p1
    },    
    {
        id:10,
        name:"payal",
        category:2,
        desc: "Click for Query",
        image: p2
    },    
    {
        id:11,
        name:"payal",
        category:2,
        desc: "Click for Query",
        image: p3
    },
    {
        id:12,
        name:"payal",
        category:2,
        desc: "Click for Query",
        image: p4
    },
    {
        id:13,
        name:"payal",
        category:3,
        desc: "Click for Query",
        image: f1
    },
    {
        id:14,
        name:"payal",
        category:3,
        desc: "Click for Query",
        image: f2
    },
    {
        id:15,
        name:"payal",
        category:3,
        desc: "Click for Query",
        image: f3
    },
    {
        id:16,
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: sa1
    },
    {
        id:17,
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: sa2
    },
    {
        id:18,
        name:"payal",
        category:1,
        desc: "Click for Query",
        image: sa3
    },
    {
        id:19,
        name:"payal",
        category:6,
        desc: "Click for Query",
        image: t1
    },
    {
        id:20,
        name:"payal",
        category:6,
        desc: "Click for Query",
        image: t2
    },
    {
        id:21,
        name:"payal",
        category:6,
        desc: "Click for Query",
        image: t3
    },

]

export default prod