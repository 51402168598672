import React, { useContext, useEffect, useState } from "react";
import whats from "../assets/whatsapp.png";
import { CurrentCat } from "../App";
import "../components/mod.scss";
import Modals from "../components/Models";

const Items = ({ currentItems, loading }) => {
  const [select, setSelect] = useState(0);
  const [selimag, setSelimag] = useState(null);
  const [
    currCat,
    setCurrCat,
    pgindex,
    setPgindex,
    currItem,
    setCurrItem,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
    open,
    setOpen,
  ] = useContext(CurrentCat);
  // const handleClick = () => {
  //   var e = document.querySelector(".modal");
  //   e.classList.toggle("activeModal");
  // };
  if (loading) {
    return <h1>Loading......</h1>;
  } else {
    return (
      <div className="flex justify-center lg:xl:w-[80%] w-[90vw]">
        <div className="lg:xl:grid flex justify-center flex-wrap lg:xl:grid-cols-4  gap-2 content-center">
          {currentItems.map((obj, index) => {
            if (obj.category === currCat || currCat === null) {
              return (
                <div
                  className="flex flex-col items-center justify-center bg-gradient-to-br lg:xl:w-[330px] w-[160px] "
                  key={index}
                >
                  <div className="overflow-hidden cursor-pointer rounded-xl relative group shadow-md">
                    <div className="rounded-xl z-20 opacity-0 lg:xl:group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
                      <div className="w-full">
                        <div
                          onClick={() => {
                            setOpen(true);
                            setSelect(obj.id);
                            setSelimag(obj.image);
                          }}
                        >
                          <div className="w-full flex justify-between p-4 space-y-3 text-xl group-hover:opacity-100 lg:xl:group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out">
                            <div>
                              <div className="font-bold my-3">{obj.name}</div>
                              <div className="opacity-60 text-sm ">
                                Tap to View
                              </div>
                            </div>
                            <img src={whats} alt="" className="w-[50px]" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <a href={`https://wa.me/8267871307?text=I'm%20interested%20in%20your%20jwelleries`} > */}
                    <div
                      onClick={() => {
                        setOpen(true);
                        setSelect(obj.id);
                        setSelimag(obj.image);
                      }}
                    >
                      <div
                        className={`${obj.id === select ? "flex" : "hidden"}`}
                      >
                        <Modals imag={selimag} />
                      </div>
                      <img
                        alt="payal jwellery"
                        className="object-fill aspect-square lg:xl:group-hover:scale-110 transition duration-300 ease-in-out lg:xl:w-[304px] xl:lg:h-[344px] w-[157px] h-[195px]"
                        src={obj.image}
                      />
                    </div>
                    {/* </a> */}
                  </div>
                  <div>
                    <small
                      className="text-sm text-[#F24949] pl-1 cursor-pointer"
                      onClick={() => {
                        setOpen(true);
                        setSelect(obj.id);
                        setSelimag(obj.image);
                      }}
                    >
                      Tap to View
                    </small>
                  </div>
                </div>
              );
            } else {
              return <div className="hidden" key={index}></div>;
            }
          })}
        </div>
      </div>
    );
  }
};

export default Items;
