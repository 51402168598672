import React, { useContext, useState } from "react";
import { CurrentCat } from "../App";

const Pagination = ({ totalItems,itemPerPage ,paginate }) => {
  const [currCat,setCurrCat,pgindex,setPgindex,currItem,setCurrItem,currentPage, setCurrentPage] = useContext(CurrentCat)
  // const [curPage,setCurPage] = useState(1)
  const pageNunber = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNunber.push(i);
  } 
  return (
    <div className="w-[80%] flex justify-center gap-3">
      {pageNunber.map((number) => {
        return (
          <div
            className={`${currentPage===number ? 'shadow-xl scale-125 mx-1':''} page lg:xl:p-3 lg:xl:py-2 p-2 py-1 hover:bg-[#df2929] bg-[#F24949] shadow-md text-white duration-[.3s] ease-in-out cursor-pointer`}
            key={number}
            onClick={()=>{paginate(number);setCurrentPage(number)}}
          >
            {number}
          </div>
        );
      })}
    </div>
  );
};

export default Pagination;
