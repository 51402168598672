import thumb1 from './assets/sada/sa1.png'
import thumb2 from './assets/parda/p1.png'
import thumb3 from './assets/fitting/f1.png'
import thumb4 from './assets/golapayal/s1.png'
import thumb5 from './assets/golapayal/s1.png'
import thumb6 from './assets/tukda/t1.png'
import thumb7 from './assets/jhallar/j1.png'
const cat = [
    {
        id:1,   
        name:"Sada Payal",
        thumbnail: thumb1
    },
    {
        id:2,
        name:"Parda Payal",
        thumbnail: thumb2
    },
    {
        id:3,
        name:"Fitting Payal",
        thumbnail: thumb3
    },
    {
        id:4,
        name:"Gola Payal",
        thumbnail: thumb4
    },
    {
        id:5,
        name:"Rakhi ",
        thumbnail: thumb5
    },
    {
        id:6,
        name:"Tukda Payal",
        thumbnail: thumb6
    },
    {
        id:7,
        name:"Jhalar Payal",
        thumbnail: thumb7
    },

]

export default cat